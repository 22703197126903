import Swiper, { Pagination } from "swiper";

let inizializedSwiper;

export default {

	init() {

		Swiper.use([Pagination]);

		inizializedSwiper = new Swiper(".swiper-container", {
			loop: true,
			slidesPerView: 1,
			spaceBetween: 0,
			pagination: {
				el: ".swiper-pagination",
			},
		});
	},
	disable() {
		if(inizializedSwiper.length ) {
			inizializedSwiper.forEach( function(element){
				element.destroy();
			});
		} else {
			inizializedSwiper.destroy();
		}
	},
}
