// eslint-disable-next-line no-undef
module.exports = {
	purge: [
		"./templates/**/*.html",
		"./templates/**/*.twig",
		"./src/**/*.js",
	],
	future: {
		removeDeprecatedGapUtilities: true,
		purgeLayersByDefault: true,
	},
	theme: {
		screens: {
			"sm": "640px",
			"md": "768px",
			"lg": "1024px",
			"xl": "1280px",
			"2xl": "1536px",
		},
		colors: {
			background: "#FFFFFF",
			fontColorHeadings: "#121212",
			fontColor: "#4F4F4F",
			primary: "#EF7D00",
			primaryShade1: "#F0C598",
			primaryShade2: "#EB600A",
			backgroundLight: "#FAF4F0",
		},
		fontFamily: {
			"argesta": ["argesta", "serif"],
			"basier-regular": ["basier-circle-regular", "sans-serif"],
			"basier-bold": ["basier-circle-semi-bold", "sans-serif"],
		},
		// use REM over PX https://gist.github.com/trevorgreenleaf/0aef4a3c33cdc8011affb170cef4384c
		fontSize: {
			"h1": "2.5rem",
			"h2": "2rem",
			"h3": "1.25rem",
			"h4": "0.875rem",
			"h1Mobile": "1.5rem",
			"h2Mobile": "1.5rem",
			"annotation": "1rem",
			"explanation": "3.125rem",
			"base": "1rem",
		},
		letterSpacing: {
			"h1": "0.125rem",
			"h2": "0.1rem",
			"h3": "0.1875rem",
			"h4": "0.156rem",
			"annotation": "0.1875rem",
		},
		lineHeight: {
			"h1": "3rem",
			"h2": "2.4rem",
			"h3": "1.5rem",
			"h4": "1.05rem",
			"h1Mobile": "1.875rem",
			"h2Mobile": "1.875rem",
			"annotation": "1.5rem",
			"explanation": "4.6875rem",
			"base": "2rem",
		},
		container: {
			padding: "1rem",
			center: true,
		},
		extend: {},
	},
	variants: {},
	plugins: [],
}
